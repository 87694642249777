import axios from "axios";

export interface AlertForm {
  title: string;
  limited_at: string;
  alert_form_items: {
    id: number;
    title: string;
    sort_order: number;
    alert_form_item_fields: {
      id: number;
      title: string;
      required: boolean;
      threshold_max: number|null,
      threshold_min: number|null,
      value: number|string,
      sort_order: number;
    }[]
  }[],
}

export class ProjectAlertFormRepository {
  private static readonly DOMAIN = process.env.VUE_APP_DOMAIN;
  private url: string;
  constructor(project_id: number) {
    this.url = ProjectAlertFormRepository.DOMAIN + 'v1/projects/' + project_id + '/alert_forms';
  }
  async getList(): Promise<any[]>{
    return axios.get(this.url).then((response) => {
      return response.data.alert_forms;
    });
  }
  async find(id: number): Promise<AlertForm> {
    return axios.get(this.url + '/' + id).then((response) => {
      return response.data
    })
  }
  async save(alert_form_id: number, values: {}[]): Promise<AlertForm> {
    const post_values = Object.entries(values).map((value) => {
      const v: number|null = value[1] !== '' ? Number(value[1]) : null;
      return {alert_form_item_field_id: Number(value[0]), value: v};
    })

    return axios.post(this.url + '/' + alert_form_id, { values: post_values }).then((response) => {
      return response.data
    })
  }
}
