import axios from 'axios';
import { ElMessage } from 'element-plus'

export const useExceptionMessage = (e: Error, message = '') => {
  if (axios.isAxiosError(e)) {
    if (e.response?.data?.code === 'INVALID-ERROR') {
      ElMessage.error({
        message: e.response.data.message.join("<br>"),
        dangerouslyUseHTMLString: true,
        showClose: true,
        duration: 0
      });
    } else {
      ElMessage.error({
        message: message.length > 0 ? message + '(' + e.message + ')' : e.message,
        dangerouslyUseHTMLString: true,
        showClose: true,
        duration: 0
      });
    }
  } else { // wafなどのパターン(railsに到達していない)
    ElMessage.warning({
      message: message.length > 0 ? message + '(' + e.message + ')' : e.message,
      dangerouslyUseHTMLString: true,
      showClose: true,
      duration: 0
    });
  }
};
